const { NODE_ENV } = process.env

export const isBrowser = !!process.browser

const debug = isBrowser && localStorage.getItem('debug') === 'true'

export const isDevelopment: boolean = NODE_ENV === 'development'

export const isDebug = process.env.NODE_ENV === 'development' || debug ? true : false

export const delay = async (ms = 1000) => {
  return new Promise((resolve) => setTimeout(resolve, ms))
}

// Highest integer we can pass to graphql is 2147483647 cents
export const MAX_INTEGER = 2147483647
export const MAX_CURRENCY = '$21,474,836.47'

export const truncate = (str: string, length: number) => {
  if (str.length > length) {
    return `${str.substring(0, length)}...`
  }
  return str
}

export function getEnumKeyByEnumValue<T extends { [index: string]: string }>(
  myEnum: T,
  enumValue: string
): keyof T | undefined {
  let keys = Object.keys(myEnum).filter((x) => myEnum[x] == enumValue)
  return keys.length > 0 ? keys[0] : undefined
}

export function getEnumKeyByEnumValueToString<T extends { [index: string]: string }>(
  myEnum: T,
  enumValue: string
): string | undefined {
  let keys = Object.keys(myEnum).filter((x) => myEnum[x] == enumValue)
  return keys.length > 0 ? keys[0].replace(/([A-Z]+)/g, ' $1').trim() : undefined
}
