import { useEffect } from 'react'
import { isDevelopment } from '@/utils'
import { Code, Heading, Text, VStack, Button, Center, Box, Flex } from '@packages/ui-components'
import useAuth from '@/hooks/useAuth'
import { captureRelayError } from '@/sentry'

import CoverImage from 'public/images/bg-cover-error.jpg'
import PageContainer from './PageContainer'
import { DashboardButton } from './ErrorPage'
import Login from '@/pages/login'

const QueryError = ({ error, capture = true }: { error: any; capture?: boolean }) => {
  const auth = useAuth()

  useEffect(() => {
    if (capture) {
      captureRelayError(error)
    }
  }, [error, capture])

  if (!auth.isLoggedIn) {
    return <Login />
  }

  if (isDevelopment) {
    return <DevErrorPage error={error} />
  } else {
    return <ErrorPage />
  }
}

const DevErrorPage = ({ error }: { error: any }) => {
  const { logout } = useAuth()

  return (
    <VStack spacing={8} p={8}>
      <Text>development environment error</Text>
      <Heading mt={8}>Ops that query failed</Heading>
      <Code colorScheme="red">{JSON.stringify(error?.message, null, 2)}</Code>
      <Button onClick={logout}>Logout</Button>
    </VStack>
  )
}

const ErrorPage = () => {
  return (
    <PageContainer title="Error" collapse showNav={false}>
      <Center
        bgImage={`url('${CoverImage}')`}
        bgPosition="center"
        bgRepeat="no-repeat"
        bgSize="cover"
        width="100%"
        h="64"
      >
        <VStack p={6} spacing={1} color="white" textAlign="center">
          <Heading fontSize="40px" lineHeight="1" letterSpacing="-3px">
            Error: Page can&apos;t load.
          </Heading>
        </VStack>
      </Center>
      <Box px="6" py="8" textAlign="center">
        <Text opacity="0.7" fontSize="md">
          Oops...looks like you’ve come off the beaten path. To ensure your finances are on track,
          please head back to your dashboard.
        </Text>
      </Box>
      <Flex align="stretch" w="full" justifyContent="center">
        <DashboardButton />
      </Flex>
    </PageContainer>
  )
}

export default QueryError
