import React, { useEffect } from 'react'
import { useQuery } from 'relay-hooks'

import { useRouter } from 'next/router'
import indexPageQuery from '../queries/indexPage'
import { LoadingSpinner } from '@packages/ui-components'
import routes from '@/routes'
import { NextPage } from 'next'
import { indexPage_indexQuery } from '@/__generated__/indexPage_indexQuery.graphql'
import QueryError from '@/components/QueryError'
import Logo from 'public/png/logo-mylifetime.png'
import { GraphQLTaggedNode } from 'relay-runtime'

const IndexQueryRender: NextPage = () => {
  const router = useRouter()
  const { data, error, isLoading } = useQuery<indexPage_indexQuery>(
    indexPageQuery as GraphQLTaggedNode
  )
  const me = data?.me
  const redirectDashboard = !isLoading && me
  const redirectToLogin = !isLoading && !me

  useEffect(() => {
    if (redirectToLogin) {
      router.push(routes.login)
    }
    if (redirectDashboard) {
      router.push(routes.dashboard)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [redirectToLogin, redirectDashboard])

  if (error && !isLoading) {
    return <QueryError error={error} />
  }
  return <LoadingSpinner image={Logo} />
}
export default IndexQueryRender
